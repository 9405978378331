<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  Closed Support Online Exam
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Closed Support Online Exam
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                      label="Name"
                      v-model="search.name"
                      outlined
                      dense
                      @input="search.name = $event !== null ? $event : ''"
                      clearable
                      v-on:keyup.enter="searchSupportOnlineExam()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3" v-if="current_user.access_type == 'federal'">
                  <v-select
                      label="SCORE"
                      v-model="search.score_id"
                      :items="scores"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense
                      @input="search.score_id = $event !== null ? $event : ''"
                      v-on:keyup.enter="searchSupportOnlineExam()"
                      clearable
                  ></v-select>
                </v-col>
                <v-col class="d-flex" cols="12" sm="6" md="3">
                  <v-select
                      :items="status"
                      v-model="search.is_active"
                      label="Status"
                      item-text="name"
                      item-value="value"
                      outlined
                      clearable
                      v-on:keyup.enter="searchSupportOnlineExam()"
                      dense
                  ></v-select>
                </v-col>


                <v-col cols="12" md="2" class="text-right">
                  <v-btn
                      @click="searchSupportOnlineExam()"
                      class="btn btn-primary"
                      style="color: #fff"
                      :loading="loading"
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>

            </div>

            <div class="table-responsive">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="loading"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>

              <table class="table">
                <thead class="d-none">
                <tr class="text-left">
                  <th class="px-3">Title</th>
                  <th class="px-3">Category</th>
                  <th class="px-3">Candidate</th>
                  <th class="px-3">Product</th>
                  <th class="px-3">Score</th>
                  <th class="px-3">Replied Date</th>
                  <th class="px-3">Created_At</th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-for="(item, index) in support_online_exams" :key="index">
                    <td class="px-2">
                      <a @click="viewOnlineExam(item.id)" class="d-flex align-items-center">
                        <div class="symbol symbol-35px me-3">
                          <div class="symbol-label bg-light-danger">
                            <span class="text-danger">{{ item.symbol_label }}</span>
                          </div>
                        </div>
                        <span class="text-primary font-weight-bolder text-hover-primary mb-1">{{ item.name }}</span>
                      </a>
                    </td>
                    <td class="px-3">
                      <span class="text-dark-75 mb-1 mt-2 d-flex align-items-center">
                        {{item.category_name }}
                      </span>
                    </td>
                    <td class="px-3">
                      <span class="text-dark-75 mb-1 mt-2 d-flex align-items-center">
                        {{item.candidate_name}}
                      </span>
                    </td>
                    <td class="px-3">
                      <span class="text-dark-75 mb-1 mt-2 d-flex align-items-center">
                        {{item.product_name ? item.product_name : '--'}}
                      </span>
                    </td>
                    <td class="px-3">
                      <span class="text-dark-75 mb-1 mt-2 d-flex align-items-center">
                       {{item.score_name ? item.score_name : '--'}}
                      </span>
                    </td>
                    <td class="px-3">
                      <a  class="text-dark-75 mb-1 mt-2 d-flex align-items-center">
                        {{item.formatted_replied_date ? item.formatted_replied_date : '--'}}
                      </a>
                    </td>
                    <td class="px-3">
                      <a  class="text-dark-75 mb-1 mt-2 d-flex align-items-center">
                        {{item.formatted_created_at ? item.formatted_created_at : '--'}}
                      </a>
                    </td>
                  </tr>

                  <tr v-if="support_online_exams.length == 0">
                    <td colspan="10" class="text-center">
                      <strong>No Data Found</strong>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination
                  v-if="support_online_exams.length > 0"
                  class="pull-right mt-7"
                  @input="getAllSupportOnlineExam"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
                  :disabled="loading"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import SupportOnlineExamService from "@/services/support/support-online-exam/SupportOnlineExamService";
import ScoreService from "@/services/score/score/ScoreService";

const supportOnlineExam = new SupportOnlineExamService();
const score = new ScoreService();
export default {
  name: "ClosedExamOnline",
  data() {
    return {
      search:{
        name: '',
        score_id: '',
        is_active: '',
      },
      status: [
        {name: 'Active', value: 1},
        {name: 'Inactive', value: 0},
      ],
      current_user: {},
      scores: [],
      support_online_exams: [],
      total: null,
      perPage: null,
      page: null,
      loading: false,
    }
  },
  methods:{
    viewOnlineExam(onlineExamId) {
      this.$router.push({
        name: 'support-online-exam-view',
        params: {onlineExamId: onlineExamId}
      })
    },
    getAllSupportOnlineExam(){
      this.loading =  true;
      supportOnlineExam
          .getAllClosed(this.search,this.page)
          .then(response => {
            this.support_online_exams=response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.loading =  false;
          })
          .catch((err) => {});
    },
    getAllScore(){
      this.loading =  true;
      score
          .all(this.search,this.page)
          .then(response => {
            this.scores = response.data.scores;
            this.loading =  false;
          })
          .catch((err) => {});
    },
    searchSupportOnlineExam(){
      this.getAllSupportOnlineExam();
    },
    getCurrentUser() {
      this.currentUser = this.$store.getters.currentUser;
    },
  },
  mounted() {
    this.current_user = this.$store.getters.currentUser;
    this.getAllSupportOnlineExam();
    this.getAllScore();
    this.getCurrentUser();
  }
}
</script>
