import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class SupportOnlineExamService {
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/support-online-exam';
    }

    paginate(data = {}, index = null) {
        let url = `${this.#api}`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }

    create(data) {
        let url = `${this.#api}`;
        return apiService.post(url, data);
    }

    update(id, data) {
        let url = `${this.#api}/${id}`
        return apiService.post(url, data)
    }

    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url)
    }

    delete(id) {
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }

    getAllClosed() {
        let url = `${this.#api}/get/all/closed`;
        return apiService.get(url);
    }

    closed(id, data) {
        let url = `${this.#api}/${id}/closed`;
        return apiService.post(url, data);
    }

    getAllCandidateOnlineExam(candidateId, data = {}, index = null) {
        let url = `${this.#api}/${candidateId}/ticket-online-exam`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }
}